import { Link } from "react-router-dom";
import Container from "components/container";
import {
  breakpoint,
  textColor,
  whiteColor,
  lightBlueColor,
  darkBlueColor,
  borderColor,
  BasicSection,
  GridPanel3,
  CardItemIndex,
} from "components/styles";
import styled from "@emotion/styled";
import { ReactComponent as CheckIcon } from "assets/icons/check-variant.svg";

const Root = styled.div({
  paddingBottom: 32,
});

const Header = styled.header({
  paddingTop: 128,
  paddingBottom: 64,
  textAlign: "center",
  color: whiteColor,
  background: [
    `linear-gradient(${darkBlueColor}cc,${darkBlueColor}cc)`,
    `url(/static/media/lighthouse.jpg) no-repeat center center / cover`,
  ].join(","),
});

const Title = styled.div({
  marginLeft: "auto",
  marginRight: "auto",
  fontSize: "4.75rem",
  fontWeight: "bold",
  [`@media (max-width: ${breakpoint}px)`]: {
    fontSize: "3rem",
  },
});

const EventYear = styled.div({
  marginBottom: -66,
  paddingTop: 64,
  fontSize: "14rem",
  fontWeight: "bold",
  opacity: 0.4,
  [`@media (max-width: ${breakpoint}px)`]: {
    marginBottom: -40,
    fontSize: "8rem",
  },
});

const EventCity = styled.div({
  fontSize: "3.2125rem",
  fontWeight: "bold",
  textTransform: "uppercase",
  [`@media (max-width: ${breakpoint}px)`]: {
    fontSize: "1.84rem",
  },
});

const EventDate = styled.div({
  paddingTop: 16,
  paddingBottom: 32,
  fontSize: "2rem",
  [`@media (max-width: ${breakpoint}px)`]: {
    fontSize: "1.5rem",
  },
});

type Panel1Props = {
  fontSize?: string;
  color?: "white";
};

const Panel1 = styled("section", {
  shouldForwardProp: (propName: string) =>
    propName !== "fontSize" && propName !== "color",
})(({ fontSize, color }: Panel1Props) => ({
  lineHeight: 1.5,
  ...(fontSize ? { fontSize } : {}),
  ...(color === "white" ? { color: whiteColor } : { color: textColor }),
}));

const HotelContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  rowGap: 8,
});

const HotelText = styled.p({
  fontSize: "1.6rem",
});

const HotelLinkContainer = styled.p({
  display: "flex",
  justifyContent: "center",
});

const RegistrationFeeContainer = styled.ul({
  display: "flex",
  flexDirection: "column",
  rowGap: 8,
});

const RegistrationFeeItem = styled.li({
  display: "flex",
  alignItems: "center",
  columnGap: 12,
  listStyle: "none",
});

const RegistrationFeeIcon = styled(CheckIcon)({
  flexShrink: 0,
  fontSize: "2.5rem",
});

const MessageBar = styled.div({
  textAlign: "center",
  fontWeight: "bold",
});

const InquiryBar = styled.div({
  paddingTop: 16,
  textAlign: "center",
  color: textColor,
});

const CardsTitle = styled.h2({
  textAlign: "center",
  fontWeight: "bold",
  "& > :first-of-type": {
    fontSize: "2.25rem",
  },
  "& > :last-of-type": {
    fontSize: "1.75rem",
    fontStyle: "italic",
  },
});

const CardItem = styled.div({
  padding: 24,
  border: `1px solid ${borderColor}`,
});

const CardItemText = styled.div({
  lineHeight: 1.5,
  paddingTop: 32,
  textTransform: "uppercase",
  fontSize: "1.25rem",
  fontWeight: "bold",
});

const ImageSection = styled.section({
  height: 256,
  background: [
    `linear-gradient(${lightBlueColor}99,${lightBlueColor}99)`,
    `url(/static/media/building.jpg) no-repeat center center / cover`,
  ].join(","),
});

const Conference = styled.div({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  gap: 32,
});

const LinkBar = styled.div({
  textAlign: "center",
});

const LinkButton = styled.a({
  display: "inline-block",
  minWidth: 240,
  padding: 16,
  textAlign: "center",
  fontWeight: "bold",
  color: whiteColor,
  backgroundColor: darkBlueColor,
  textDecoration: "none",
  "&[data-disabled]": {
    opacity: 0.75,
    pointerEvents: "none",
  },
});

const LinkList = styled.ul({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-evenly",
  alignItems: "center",
  gap: 32,
  marginLeft: "auto",
  marginRight: "auto",
});

const LinkItem = styled.li({
  listStyleType: "none",
});

type ImageProps = {
  padding?: "compact";
};

const SOAImage = styled("img", {
  shouldForwardProp: (propName: string) => propName !== "padding",
})(({ padding }: ImageProps) => ({
  height: padding === "compact" ? 60 : 80,
  [`@media (max-width: ${breakpoint}px)`]: {
    height: padding === "compact" ? 45 : 60,
  },
}));

const NexusImage = styled("img", {
  shouldForwardProp: (propName: string) => propName !== "padding",
})(({ padding }: ImageProps) => ({
  height: padding === "compact" ? 90 : 120,
  objectPosition: "0 -7px",
  [`@media (max-width: ${breakpoint}px)`]: {
    height: padding === "compact" ? 67.5 : 90,
    objectPosition: "0 -5.25px",
  },
}));

const CboeImage = styled("img", {
  shouldForwardProp: (propName: string) => propName !== "padding",
})(({ padding }: ImageProps) => ({
  height: padding === "compact" ? 45 : 60,
  [`@media (max-width: ${breakpoint}px)`]: {
    height: padding === "compact" ? 33.75 : 45,
  },
}));

const EBIG2024 = () => (
  <Root>
    <Header>
      <Container>
        <Title>
          Equity-Based Insurance Guarantee Conference and Webcast Series
        </Title>
        <EventYear>2024</EventYear>
        <EventCity>Chicago, Illinois</EventCity>
        <EventDate>Nov 18-19</EventDate>
        <LinkList>
          <LinkItem>
            <a
              href="https://www.soa.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SOAImage
                src="/static/media/soa-light.png"
                alt="Society of Actuaries"
              />
            </a>
          </LinkItem>
          <LinkItem>
            <Link to="/">
              <NexusImage
                src="/static/media/nexus-light.png"
                alt="Nexus Risk Management"
              />
            </Link>
          </LinkItem>
          <LinkItem>
            <a
              href="https://www.cboe.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CboeImage src="/static/media/cboe-light.png" alt="Cboe" />
            </a>
          </LinkItem>
        </LinkList>
      </Container>
    </Header>
    <BasicSection>
      <Container>
        <Panel1 fontSize="1.6rem">
          <p>
            After a five-year hiatus, the Equity-Based Insurance Guarantee
            Conference will be re-launched on November 18-19, 2024 at Cboe in
            Chicago. As a lead into this conference, the Society of Actuaries
            and Nexus Risk Management are presenting a three-part webcast series
            covering the latest generation of index-linked annuities and delving
            into the associated risk management, reserving and capital issues.
            The final webcast will provide an overview of all the important
            issues presented and discussed at the face-to-face meeting in
            November.
          </p>
        </Panel1>
      </Container>
    </BasicSection>
    <BasicSection padding="compact" gap="compact" background="dark-blue">
      <Container>
        <Panel1 fontSize="1.8rem" color="white">
          <p>
            We hope you will join us for the return of the Equity-Based
            Insurance Guarantee Conference and Webcast Series!
          </p>
        </Panel1>
      </Container>
    </BasicSection>
    <BasicSection gap="compact">
      <Container>
        <Panel1>
          <CardsTitle>
            <div>Equity-Based Insurance Guarantee Webcast Series</div>
            <div>(On-Line)</div>
          </CardsTitle>
        </Panel1>
        <GridPanel3 even>
          <CardItem>
            <CardItemIndex>I.</CardItemIndex>
            <CardItemText>
              <div>Wednesday, May 22</div>
              <div>12:00 - 1:15 PM</div>
            </CardItemText>
            <CardItemText>
              <a href="https://www.soa.org/prof-dev/webcasts/2024-equity-based-ins-guarantee-series/">
                The Evolving Landscape of Indexed-Linked Annuities
              </a>
            </CardItemText>
          </CardItem>
          <CardItem>
            <CardItemIndex>II.</CardItemIndex>
            <CardItemText>
              <div>Monday, June 17</div>
              <div>12:00 - 1:15 PM</div>
            </CardItemText>
            <CardItemText>
              <a href="https://www.soa.org/prof-dev/webcasts/2024-equity-based-ins-guarantee-series/">
                The Challenges of Managing Indexed-Linked Annuities
              </a>
            </CardItemText>
          </CardItem>
          <CardItem>
            <CardItemIndex>III.</CardItemIndex>
            <CardItemText>
              <div>Friday, December 13</div>
              <div>12:00 - 1:15 PM</div>
            </CardItemText>
            <CardItemText>
              <a href="https://www.soa.org/prof-dev/webcasts/2024-equity-based-ins-guarantee-series/">
                EBIG Conference Highlights and Key Messages
              </a>
            </CardItemText>
          </CardItem>
        </GridPanel3>
        <Panel1>
          <LinkList>
            <LinkItem>
              <a
                href="https://www.soa.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SOAImage
                  padding="compact"
                  src="/static/media/soa-dark.png"
                  alt="Society of Actuaries"
                />
              </a>
            </LinkItem>
            <LinkItem>
              <Link to="/">
                <NexusImage
                  padding="compact"
                  src="/static/media/nexus-dark.png"
                  alt="Nexus Risk Management"
                />
              </Link>
            </LinkItem>
          </LinkList>
        </Panel1>
        <Panel1>
          <LinkBar>
            <LinkButton
              href="https://www.soa.org/prof-dev/webcasts/2024-equity-based-ins-guarantee-series/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Register
            </LinkButton>
          </LinkBar>
        </Panel1>
      </Container>
    </BasicSection>
    <ImageSection />
    <BasicSection gap="compact" background="dark-blue">
      <Container>
        <Panel1 color="white">
          <CardsTitle>
            <div>Equity-Based Insurance Guarantee Conference</div>
            <div>(In-Person)</div>
          </CardsTitle>
        </Panel1>
        <Panel1 fontSize="1.75rem" color="white">
          <div>November 18-19, 2024</div>
          <div>Chicago, IL</div>
        </Panel1>
        <Panel1 fontSize="1.3rem" color="white">
          <Conference>
            <div>
              <div>Presented by: Nexus Risk Management</div>
              <div>Sponsored by: Cboe Global Markets</div>
              <div>Chairperson: Dr. Kannoo (Ravi) Ravindran</div>
            </div>
            <div>
              <div>Venue:</div>
              <div>The Marquee Conference Center</div>
              <div>The Old Post Office (New Cboe Headquarters)</div>
              <div>433 W. Van Buren Street, Chicago, IL 60607</div>
            </div>
          </Conference>
        </Panel1>
      </Container>
    </BasicSection>
    <BasicSection padding="compact" gap="compact">
      <Container>
        <Panel1>
          <HotelContainer>
            <HotelText>
              The hotel for the event is The LaSalle Chicago, Autograph
              Collection. Please use the link below to obtain the group rate of
              325 USD per night.
            </HotelText>
            <HotelLinkContainer>
              <LinkButton
                href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1717774306117&key=GRP&app=resvlink"
                target="_blank"
                rel="noopener noreferrer"
              >
                Book your group rate for Cboe + EBIG Room Block
              </LinkButton>
            </HotelLinkContainer>
          </HotelContainer>
        </Panel1>
      </Container>
    </BasicSection>
    <BasicSection padding="compact" gap="compact" background="dark-blue">
      <Container>
        <Panel1 fontSize="1.6rem" color="white">
          <RegistrationFeeContainer>
            <RegistrationFeeItem>
              <RegistrationFeeIcon />
              Registration is $1,600
            </RegistrationFeeItem>
            {/* <RegistrationFeeItem>
              <RegistrationFeeIcon />
              Early bird registration before September 30, 2024 is $1,475
            </RegistrationFeeItem> */}
          </RegistrationFeeContainer>
        </Panel1>
      </Container>
    </BasicSection>
    <BasicSection padding="compact" gap="compact" background="light-blue">
      <Container>
        <Panel1 fontSize="2.25rem">
          <MessageBar>See you at the Exchange!</MessageBar>
        </Panel1>
      </Container>
    </BasicSection>
    <BasicSection padding="compact" gap="compact">
      <Container>
        <Panel1>
          <LinkList>
            <LinkItem>
              <Link to="/">
                <NexusImage
                  padding="compact"
                  src="/static/media/nexus-dark.png"
                  alt="Nexus Risk Management"
                />
              </Link>
            </LinkItem>
            <LinkItem>
              <a
                href="https://www.cboe.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CboeImage
                  padding="compact"
                  src="/static/media/cboe-dark.png"
                  alt="Cboe"
                />
              </a>
            </LinkItem>
          </LinkList>
        </Panel1>
        <Panel1>
          <LinkBar>
            <LinkButton
              href="https://cvent.me/KYbk2l"
              target="_blank"
              rel="noopener noreferrer"
            >
              Register
            </LinkButton>
          </LinkBar>
        </Panel1>
        <Panel1>
          <LinkList>
            <LinkItem>
              <LinkButton
                href="/documents/ebig-2024/EBIG Agenda.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Program Agenda
              </LinkButton>
            </LinkItem>
            <LinkItem>
              <LinkButton href="#" data-disabled>
                Sponsors
              </LinkButton>
            </LinkItem>
          </LinkList>
        </Panel1>
        <InquiryBar>
          For more information and sponsorship inquiries please contact{" "}
          <a href="mailto:inquiries@nexusrisk.com">inquiries@nexusrisk.com</a>
        </InquiryBar>
      </Container>
    </BasicSection>
  </Root>
);

export default EBIG2024;
